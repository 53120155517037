import React from 'react';
import { Tabs as AccessibleTabs, Tab, TabList, TabPanel } from 'react-tabs';

import * as styles from './Tabs.module.css';

import Content from '@components/Layout/Content/Content';

const Tabs = ({
	tabs,
}: {
	tabs: {
		tab_button: string;
		tab_panel: any;
	}[];
}) => {
	return (
		<AccessibleTabs className={styles.layout}>
			<TabList className={styles.list}>
				{tabs.map(({ tab_button }) => (
					<Tab className={styles.tab}>{tab_button}</Tab>
				))}
			</TabList>

			{tabs.map(({ tab_panel }) => (
				<TabPanel className={styles.panel}>
					<Content>
						<div
							dangerouslySetInnerHTML={{
								__html: tab_panel.html,
							}}></div>
					</Content>
				</TabPanel>
			))}
		</AccessibleTabs>
	);
};

export default Tabs;
