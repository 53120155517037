import React from 'react';

import * as styles from './Table.module.css';

const Table = ({ tableHTML }: { tableHTML: string }) => {
	return (
		<div
			className={styles.table}
			dangerouslySetInnerHTML={{
				__html: tableHTML,
			}}
		/>
	);
};

export default Table;
