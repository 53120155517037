import { LINKS } from '@utils/constants';
import { bytesToSize, getExtension } from '@utils/helpers';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import BorderImage from '@components/BorderImage/BorderImage';
import Box from '@components/Box/Box';
import ButtonGroup from '@components/Layout/ButtonGroup/ButtonGroup';
import Columns from '@components/Layout/Columns/Columns';
import Content from '@components/Layout/Content/Content';
import PageSection from '@components/Layout/PageSection/PageSection';
import List from '@components/List/List';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Breadcrumbs from '@components/Molecule/Breadcrumbs/Breadcrumbs';
import Button from '@components/Molecule/Button/Button';
import Table from '@components/Molecule/Table/Table';
import Tabs from '@components/Molecule/Tabs/Tabs';
import EmbedVideo from '@components/Molecule/Video/EmbedVideo';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const ProductTemplate = ({ pageContext }: { pageContext: any }) => {
	const { crumbs } = pageContext.breadcrumb;
	const { childImageSharp } = pageContext.fallbackImage;

	const {
		title,
		summary,
		content,
		product_image,
		key_specifications,
		downloads,
		additional_content,
		cadenas_configurator_url,
		videos,
		specification_table,
		tabs,
		seo_title,
		seo_description,
		seo_image,
	} = pageContext.product.data;

	const fitleredDownloads = downloads.filter(
		(object, { download_title, download_file }, key) =>
			download_title !== null && download_file !== null ? object : null
	);

	return (
		<Page>
			<SEO
				title={`${seo_title ? seo_title : title ? title : null
					} | UHV Design`}
				description={seo_description || summary.text}
				image={
					seo_image?.gatsbyImageData?.images.fallback.src ||
					product_image?.gatsbyImageData?.images.fallback.src
				}
			/>

			<PageSection>
				<Breadcrumbs crumbs={crumbs} />

				<Columns gap="lg">
					<div>
						<Content uniformHeadings>
							{title && (
								<div>
									<MotionH level={2} as="h1">
										{title}
									</MotionH>
									{summary?.text && (
										<MotionCopy weight="bold" spacing="sm">
											{summary.text}
										</MotionCopy>
									)}
								</div>
							)}

							{cadenas_configurator_url && (
								<ButtonGroup spacing="md">
									<Button
										to="#configurator-tool"
										variant="configure"
										size="sm">
										Configure
									</Button>
								</ButtonGroup>
							)}

							{content?.html && (
								<motion.div
									initial="hidden"
									whileInView="visible"
									viewport={{ once: true, amount: 0.1 }}
									transition={{ duration: 0.35 }}
									variants={{
										visible: { opacity: 1, translateY: 0 },
										hidden: {
											opacity: 0,
											translateY: '1rem',
										},
									}}
									dangerouslySetInnerHTML={{
										__html: content.html,
									}}></motion.div>
							)}
						</Content>

						{key_specifications?.html && (
							<Box>
								<Content spacing="sm">
									<H level={3} as="h2">
										Key Specifications
									</H>

									<div
										dangerouslySetInnerHTML={{
											__html: key_specifications?.html,
										}}></div>
								</Content>
							</Box>
						)}
					</div>

					<div>
						<BorderImage
							image={getImage(
								product_image?.gatsbyImageData
									? product_image.gatsbyImageData
									: childImageSharp.gatsbyImageData
							)}
							alt={
								product_image?.alt
									? product_image.alt
									: title
										? title
										: ''
							}
						/>

						{fitleredDownloads?.length > 0 && (
							<>
								<MotionH level={3} as="h2" spacing="xl">
									Product Downloads
								</MotionH>
								<List>
									{fitleredDownloads.map(
										(
											{
												download_file,
												download_title,
											}: {
												download_file: {
													url: string;
													size: number;
												};
												download_title: string;
											},
											key: number
										) => {
											if (
												download_file === null ||
												download_title === null
											)
												return null;

											const fileType = download_file.url;
											const fileSize = download_file.size;

											return (
												<motion.li
													key={key}
													initial="hidden"
													whileInView="visible"
													viewport={{
														once: true,
														amount: 0.6,
													}}
													transition={{
														duration: 0.35,
														delay: key
															? 0.05 + 0.02 * key
															: 0,
													}}
													variants={{
														visible: {
															opacity: 1,
															translateY: 0,
														},
														hidden: {
															opacity: 0,
															translateY: '1rem',
														},
													}}>
													{download_title}
													<Button
														to={download_file.url}
														variant="download">
														{`${getExtension(
															fileType
														)} (${bytesToSize(
															fileSize
														)})`}
													</Button>
												</motion.li>
											);
										}
									)}
								</List>
							</>
						)}
					</div>
				</Columns>
			</PageSection>

			{additional_content?.html && (
				<PageSection>
					<Content uniformHeadings constrict>
						<motion.div
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true, amount: 0.1 }}
							transition={{ duration: 0.35 }}
							variants={{
								visible: { opacity: 1, translateY: 0 },
								hidden: {
									opacity: 0,
									translateY: '1rem',
								},
							}}
							dangerouslySetInnerHTML={{
								__html: additional_content.html,
							}}></motion.div>
					</Content>
				</PageSection>
			)}

			{cadenas_configurator_url && (
				<PageSection id="configurator-tool">
					<MotionH level={3} as="h2">
						Product configuration tool
					</MotionH>

					<motion.iframe
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true, amount: 0.1 }}
						transition={{ duration: 0.35 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}
						src={cadenas_configurator_url}
						frameBorder="0"
						width="100%"
						height="700px"
						title="CADENAS"></motion.iframe>
				</PageSection>
			)}

			{videos?.length > 0 &&
				videos?.some(({ video_url }) => video_url !== null) && (
					<PageSection>
						{videos.map(
							(
								{
									video_url,
								}: {
									video_url: {
										embed_url: string;
										title: string;
									};
								},
								key: number
							) => {
								if (video_url === null) return null;

								return (
									<EmbedVideo
										src={video_url?.embed_url}
										title={
											video_url?.title
												.charAt(0)
												.toUpperCase() +
											video_url?.title
												.slice(1)
												.toLowerCase()
										}
										showTitle
										key={key}
									/>
								);
							}
						)}
					</PageSection>
				)}

			{specification_table.text && (
				<PageSection>
					<H level={3} as="h2">
						Technical Specification
					</H>
					<Table tableHTML={specification_table.text} />
				</PageSection>
			)}

			{tabs.length > 0 &&
				tabs?.some(({ tab_button }) => tab_button !== null) && (
					<PageSection>
						<H level={3} as="h2">
							Configuration options
						</H>

						<Tabs tabs={tabs} />
					</PageSection>
				)}

			<PageSection background="blue">
				<Copy weight="bold" font="xl2" align="center" prose={true}>
					If you would like to discuss your project with our
					engineering team, please{' '}
					<Link to={LINKS.CONTACT} className="underline">
						contact us
					</Link>
					.
				</Copy>
			</PageSection>
		</Page>
	);
};

export default ProductTemplate;
